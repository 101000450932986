import React, { useEffect, useState } from 'react';
import './Counter.css';
import AOS from "aos";
import "aos/dist/aos.css";

function animateCounter(element, start, end, duration) {
  let range = end - start;
  let stepTime = Math.abs(Math.floor(duration / range));
  let startTime = new Date().getTime();
  let endTime = startTime + duration;
  let timer;

  function run() {
    let now = new Date().getTime();
    let remaining = Math.max((endTime - now) / duration, 0);
    let value = Math.round(end - (remaining * range));
    element.innerHTML = value;

    if (value === end) {
      clearInterval(timer);
      element.innerHTML = value + '+';
    }
  }

  timer = setInterval(run, stepTime);
  run();
}

function Counter() {
  const [counters, setCounters] = useState([]);

  // useEffect(() => {
  //   // window.scrollTo(0,0);
  //   AOS.init();
  //   AOS.refresh();
  // }, []);

  useEffect(() => {
    const counterElements = document.querySelectorAll('.counter');
    setCounters(counterElements);
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    counters.forEach((counter) => {
      const startValue = parseInt(counter.getAttribute('data-start'), 10);
      const endValue = parseInt(counter.getAttribute('data-end'), 10);
      const duration = parseInt(counter.getAttribute('data-duration'), 10);

      animateCounter(counter, startValue, endValue, duration);
    });
  }, [counters]);

  return (
    <div data-aos="zoom-in" className="cont">
      <div className="counter-container">
        <div id="counter1" className="counter" data-start="0" data-end="10" data-duration="3000"></div>
        <span className='count-txt'>Awards and Recognitions</span>
      </div>
      <div className="counter-container">
        <div id="counter2" className="counter" data-start="0" data-end="100" data-duration="3000"></div>
        <span className='count-txt'>Number of Tours Conducted</span>
      </div>
      <div className="counter-container">
        <div id="counter3" className="counter" data-start="0" data-end="10" data-duration="3000"></div>
        <span className='count-txt'>Countries Explored</span>
      </div>
      <div className="counter-container">
        <div id="counter3" className="counter" data-start="0" data-end="10" data-duration="3000"></div>
        <span className='count-txt'>Years of experience</span>
      </div>
    </div>
  );
}

export default Counter;
