import React, { useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import { Button, Pane } from 'evergreen-ui';
import './AdminMenu.css';

function AdminMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.state?.token; // Retrieve the token from location state

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.role !== 'admin') {
          navigate('/error404'); // Redirect to error page if not admin
        }
      } catch (error) {
        console.error('Invalid token:', error);
        navigate('/error404'); // Redirect to error page on token decode failure
      }
    } else {
      navigate('/error404'); // Redirect if no token is provided
    }
  }, [token, navigate]);

  return (
    <Pane className="admin-container" display="flex" justifyContent="center" alignItems="center" height="100vh">
      <Pane className="admin-menu" display="flex" flexDirection="column" gap={16}>
        <Link to='/admin-destination'>
          <Button appearance="primary" intent="success">Create New Destinations List</Button>
        </Link>
        <Link to='/admin-tour'>
          <Button appearance="primary" intent="success">Create New Tours Packages</Button>
        </Link>
        <Link to='/tour-edit'>
          <Button appearance="primary" intent="success">View and Edit Tour Packages</Button>
        </Link>
        <Link to='/destination-edit'>
          <Button appearance="primary" intent="success">View and Edit Destinations</Button>
        </Link>
        <Link to='/user-edit'>
          <Button appearance="primary" intent="success">View and Edit Users</Button>
        </Link>
        <Link to='/booked-receipts'>
          <Button appearance="primary" intent="success">Booking Receipts</Button>
        </Link>
      </Pane>
    </Pane>
  );
}

export default AdminMenu;
